import React, { useState, useEffect } from "react";
import axios from "axios";
import "./App.css";
import FormPage from "./Components/formpage";
import ChatPage from "./Components/chatpage";
import MedFormPage from "./Components/responsiveformpage";
import BinaryFormPage from "./Components/binaryformpage";
import EndPage from "./Components/endpage";
import audioServiceInstance from "./Components/audioService";

function App() {
    const pathParts = window.location.href.split("/");
    const patient_id = pathParts[pathParts.length - 1];

    const [initialQuestions, setInitialQuestions] = useState({});
    const [isConversationStarted, setIsConversationStarted] = useState(false);
    const [stageNumber, setStageNumber] = useState(-1);
    const [loading, setLoading] = useState(false);
    const [chatHistory, setChatHistory] = useState([]);
    const [isPlayingAudio, setIsPlayingAudio] = useState(false);
    const [isNewPatient, setIsNewPatient] = useState(false);

    useEffect(() => {
        const fetchPatientStatus = async () => {
            try {
                const response = await axios.get(`https://breezybackend.azurewebsites.net/patients/${patient_id}`);
                console.log(response.data);
                const patientData = response.data;
                const isNew = patientData.new;
                setIsNewPatient(isNew);
                const questions = getInitialQuestions(isNew);
                setInitialQuestions(questions);
                console.log('Initial Questions:', questions);
            } catch (error) {
                console.error("Error fetching patient status:", error);
            }
        };

        
        fetchPatientStatus();
    }, [patient_id]);

    const getInitialQuestions = (isNew) => {
        if (isNew) {
            return {
                "Legal Name": "",
                "Date of Birth": "",
                "Sex at Birth": "",
                "SSN": "",
                "Mobile Information": "",
                "Email": "",
                "Marital Status": "",
                "Address": "",
                "Ethnic Information": "",
                "Additional Demographic Questions": "",
                "Emergency Contact": "",
                "What is your approximate height?": "4'0\"",
                "What is your approximate weight?": "",
                "Have you started any new medications?": "",
                "Have you had any recent surgeries?": "",
                "Do you have any known drug allergies?": "",
                "Do you smoke or vape?": "",
                "Do you have exposure to 2nd-hand smoke or passive exposure?": "",
                "Do you consume smokeless tobacco products? (Chewing tobacco, Zyn, nicotine patches, etc.)": "",
                "Do you consume alcohol or other drugs?": "",
                "Do you have a regular exercise habit?": "",
                "Finally, what is the main reason for your visit?": ""
            };
        } else {
            return {
                "What is your first and last name?": "",
                "Have you started any new medications?": "",
                "Have you had any recent surgeries?": "",
                "Do you have any known drug allergies?": "",
                "Do you smoke or vape?": "",
                "Do you have exposure to 2nd-hand smoke or passive exposure?": "",
                "Do you consume smokeless tobacco products? (Chewing tobacco, Zyn, nicotine patches, etc.)": "",
                "Do you consume alcohol or other drugs?": "",
                "Do you have a regular exercise habit?": "",
                "Finally, what is the main reason for your visit?": ""
            };
        }
    };
    

    const handleSubmission = (input, stageNumber) => {
        const questionKey = Object.keys(initialQuestions)[stageNumber];
        const updatedQuestions = {
            ...initialQuestions,
            [questionKey]: input,
        };
        setInitialQuestions(updatedQuestions);
    };

    const handlePlayAudio = async (audioUrl) => {
        try {
            setIsPlayingAudio(true);
            await audioServiceInstance.playAudio(audioUrl);
        } catch (error) {
            console.error("Error playing audio:", error);
        } finally {
            setIsPlayingAudio(false);
        }
    };

    const startConversation = async () => {
        try {
            setLoading(true);
            const response = await axios.post(
                `https://breezybackend.azurewebsites.net/start/${patient_id}`,
                initialQuestions,
                {
                    headers: { "Content-Type": "application/json" },
                }
            );
            setChatHistory([
                { role: "assistant", content: response.data.initial_response },
            ]);

            const audioUrl = await audioServiceInstance.fetchAudio(
                response.data.initial_response
            );

            setIsConversationStarted(true);
            setLoading(false);

            if (audioUrl) {
                try {
                    await handlePlayAudio(audioUrl); // Play the nurse's response
                } catch (error) {
                    console.error("Error playing audio:", error);
                }
            }
        } catch (error) {
            console.error("Error starting conversation:", error);
            setLoading(false);
        }
    };

    return (
        <div
            style={{
                display: "flex",
                height: "85vh",
                justifyContent: "center",
                background: "white",
                color: "black",
            }}
        >
            {(!isNewPatient ? (
                !isConversationStarted ? (
                    ((stageNumber === -1) && (
                        <FormPage
                            stageNumber={stageNumber}
                            setStageNumber={setStageNumber}
                            question={
                                "I become more intelligent the more you share, so I'll ask some questions that will aid me in assisting you."
                            }
                            handleSubmission={() => {
                                return;
                            }}
                            submitLabel={"Okay"}
                            inputs={[]}
                        />
                    )) ||
                    (((stageNumber === 0) || stageNumber === 9) && (
                        <>
                            <FormPage
                                stageNumber={stageNumber}
                                setStageNumber={setStageNumber}
                                question={Object.keys(initialQuestions)[stageNumber]}
                                handleSubmission={handleSubmission}
                                submitLabel={
                                    stageNumber === 9 ? "Submit Reason" : "Next"
                                }
                                inputs={
                                    stageNumber === 0
                                        ? [
                                              {
                                                  inputType: "text",
                                                  inputLabel: "Full Name",
                                              },
                                          ]
                                        : stageNumber === 9
                                        ? [
                                              {
                                                  inputType: "text",
                                                  inputLabel: "Reason for Visit",
                                              },
                                          ]
                                        : []
                                }
                            />
                        </>
                    )) ||
                    (stageNumber >= 1 && stageNumber < 4 && (
                        <>
                            <MedFormPage
                                stageNumber={stageNumber}
                                setStageNumber={setStageNumber}
                                question={Object.keys(initialQuestions)[stageNumber]}
                                handleSubmission={handleSubmission}
                                submitLabel="Next"
                                inputs={
                                    stageNumber === 1
                                        ? [
                                              {
                                                  inputType: "text",
                                                  inputLabel: "Medication",
                                              },
                                              {
                                                  inputType: "text",
                                                  inputLabel: "Dosage",
                                              },
                                          ]
                                        : stageNumber === 2
                                        ? [
                                              {
                                                  inputType: "text",
                                                  inputLabel: "Surgery",
                                              },
                                              {
                                                  inputType: "date",
                                                  inputLabel: "Date",
                                              },
                                          ]
                                        : stageNumber === 3
                                        ? [
                                              {
                                                  inputType: "text",
                                                  inputLabel: "Drug Allergies",
                                              },
                                          ]
                                        : []
                                }
                            />
                        </>
                    )) ||
                    (stageNumber === 10 && (
                        <EndPage
                            stageNumber={stageNumber}
                            setStageNumber={setStageNumber}
                            question={
                                "Thank you for the information so far! You will now begin a quick conversation with Ava, your virtual nurse. This will be a verbal conversation just as you may have with any other nurse."
                            }
                            handleSubmission={startConversation}
                            submitLabel={"Start Conversation"}
                            loading={loading} 
                        />
                    )) ||
                    (stageNumber >= 4 && stageNumber < 9 && (
                        <BinaryFormPage
                            stageNumber={stageNumber}
                            setStageNumber={setStageNumber}
                            question={Object.keys(initialQuestions)[stageNumber]}
                            handleSubmission={handleSubmission}
                            submitLabel="Next"
                        />
                    ))
                ) : (
                    <ChatPage
                        patient_id={patient_id}
                        loading={loading}
                        setLoading={setLoading}
                        chatHistory={chatHistory}
                        setChatHistory={setChatHistory}
                    />
                )
            ) : (
                !isConversationStarted ? (
                    ((stageNumber === -1) && (
                        <FormPage
                            stageNumber={stageNumber}
                            setStageNumber={setStageNumber}
                            question={
                                "Welcome! I need to gather some initial information to set up your profile."
                            }
                            handleSubmission={() => {
                                return;
                            }}
                            submitLabel={"Okay"}
                            inputs={[]}
                        />
                    )) ||
                    ((stageNumber >= 0 && stageNumber < 10) && (
                        <>
                            <FormPage
                                stageNumber={stageNumber}
                                setStageNumber={setStageNumber}
                                question={Object.keys(initialQuestions)[stageNumber]}
                                handleSubmission={handleSubmission}
                                submitLabel="Next"
                                inputs={
                                    stageNumber === 0
                                        ? [
                                              {
                                                  inputType: "text",
                                                  inputLabel: "First Name",
                                              },
                                              {
                                                  inputType: "text",
                                                  inputLabel: "Last Name",
                                              },
                                          ]
                                        : stageNumber === 1
                                        ? [
                                              {
                                                  inputType: "date",
                                                  inputLabel: "Date of Birth",
                                              },
                                          ]
                                        : stageNumber === 2
                                        ? [
                                              {
                                                  inputType: "checkbox",
                                                  options: ["Male", "Female", "Other"]
                                              },
                                          ]
                                        : stageNumber === 6
                                        ? [
                                              {
                                                  inputType: "checkbox",
                                                  options: ["Single", "Married", "Divorced", "Widowed/er"]
                                              },
                                          ]
                                        : stageNumber === 3
                                        ? [
                                              {
                                                  inputType: "number",
                                                  inputLabel: "SSN",
                                              },
                                          ]
                                        : stageNumber === 4
                                        ? [
                                              {
                                                  inputType: "number",
                                                  inputLabel: "Mobile Phone",
                                              },
                                              {
                                                inputLabel: "May we leave detailed messages at this phone?",
                                                inputType: "checkbox",
                                                options: ["Yes", "No"],
                                              },
                                          ]
                                        : stageNumber === 5
                                        ? [
                                              {
                                                  inputType: "email",
                                                  inputLabel: "Email",
                                              },
                                          ]
                                        : stageNumber === 7
                                        ? [
                                              {
                                                  inputType: "text",
                                                  inputLabel: "Address",
                                              },
                                              {
                                                  inputType: "text",
                                                  inputLabel: "Suite/Apt #",
                                              },
                                              {
                                                  inputType: "text",
                                                  inputLabel: "City",
                                              },
                                              {
                                                  inputType: "text",
                                                  inputLabel: "State",
                                              },
                                              {
                                                  inputType: "number",
                                                  inputLabel: "Zip",
                                              },
                                          ]
                                        : stageNumber === 8
                                        ? [
                                              {
                                                  inputLabel: "Race", 
                                                  inputType: "checkbox",
                                                  options: ["American Indian/Alaska Native", "Asian", "Black/African American", "Native Hawaiian/Pacific Islander", "White", "Decline to Specify"]
                                              },
                                              {
                                                inputLabel: "Ethnicity", 
                                                  inputType: "checkbox",
                                                  options: ["Hispanic/Latino", "Not Hispanic/Latino", "Decline to Specify"]
                                              },
                                          ]
                                        : stageNumber === 9
                                        ? [
                                              {
                                                  inputLabel: "Are you a veteran",
                                                  inputType: "checkbox",
                                                  options: ["Yes", "No"]
                                              },
                                              {
                                                  inputLabel: "Organ Donor",
                                                  inputType: "checkbox",
                                                  options: ["Yes", "No"]
                                              },
                                              {
                                                  inputLabel: "Left/Right Handed",
                                                  inputType: "checkbox",
                                                  options: ["Left", "Right"]
                                              },
                                          ]
                                        : []
                                }
                            />
                        </>
                    )) ||
                    ((stageNumber >= 10 && stageNumber <= 12) && (
                        <>
                            <FormPage
                                stageNumber={stageNumber}
                                setStageNumber={setStageNumber}
                                question={Object.keys(initialQuestions)[stageNumber]}
                                handleSubmission={handleSubmission}
                                submitLabel="Next"
                                inputs={
                                    stageNumber === 10
                                        ? [
                                              {
                                                  inputType: "text",
                                                  inputLabel:
                                                      "Emergency Contact First Name",
                                              },
                                              {
                                                  inputType: "text",
                                                  inputLabel:
                                                      "Emergency Contact Last Name",
                                              },
                                              {
                                                  inputType: "text",
                                                  inputLabel:
                                                      "Emergency Contact Relationship",
                                              },
                                              {
                                                  inputType: "number",
                                                  inputLabel:
                                                      "Emergency Contact Phone Number",
                                              },
                                          ]
                                        : stageNumber === 11
                                        ? [
                                            {
                                                inputType: "number",
                                                inputLabel: "# Feet",
                                            },
                                            {
                                                inputType: "number",
                                                inputLabel: "# Inches",
                                            }
                                          ]
                                        : stageNumber === 12
                                        ? [
                                            {
                                                inputType: "number",
                                                inputLabel: "# Pounds",
                                            }
                                        ]
                                        : []
                                }
                            />
                        </>
                    )) ||
                    ((stageNumber >= 13 && stageNumber < 16) && (
                        <MedFormPage
                            stageNumber={stageNumber}
                            setStageNumber={setStageNumber}
                            question={Object.keys(initialQuestions)[stageNumber]}
                            handleSubmission={handleSubmission}
                            submitLabel="Next"
                            inputs={
                                stageNumber === 13
                                    ? [
                                          {
                                              inputType: "text",
                                              inputLabel: "Medication",
                                          },
                                          {
                                              inputType: "text",
                                              inputLabel: "Dosage",
                                          },
                                      ]
                                    : stageNumber === 14
                                    ? [
                                          {
                                              inputType: "text",
                                              inputLabel: "Surgery",
                                          },
                                          {
                                              inputType: "date",
                                              inputLabel: "Date",
                                          },
                                      ]
                                    : stageNumber === 15
                                    ? [
                                          {
                                              inputType: "text",
                                              inputLabel: "Drug Allergies",
                                          },
                                      ]
                                    : []
                            }
                        />
                    )) ||
                    ((stageNumber >= 16 && stageNumber < 21) && (
                        <BinaryFormPage
                            stageNumber={stageNumber}
                            setStageNumber={setStageNumber}
                            question={Object.keys(initialQuestions)[stageNumber]}
                            handleSubmission={handleSubmission}
                            submitLabel="Next"
                        />
                    )) ||
                    (stageNumber === 22 && (
                        <EndPage
                            stageNumber={stageNumber}
                            setStageNumber={setStageNumber}
                            question={
                                "Thank you for the information so far! You will now begin a quick conversation with Ava, your virtual nurse. This will be a verbal conversation just as you may have with any other nurse."
                            }
                            handleSubmission={startConversation}
                            submitLabel={"Start Conversation"}
                            loading={loading}
                        />
                    )) ||
                    (stageNumber === 21 && (
                        <FormPage
                            stageNumber={stageNumber}
                            setStageNumber={setStageNumber}
                            question={"Finally, what is the main reason for your visit?"}
                            handleSubmission={handleSubmission}
                            submitLabel="Next"
                            inputs={[
                                {
                                    inputType: "text",
                                    inputLabel: "Reason for Visit",
                                },
                            ]}
                        />
                    ))
                ) : (
                    <div>
                        {isPlayingAudio && <p>Playing audio...</p>}
                        <ChatPage
                            patient_id={patient_id}
                            loading={loading}
                            setLoading={setLoading}
                            chatHistory={chatHistory}
                            setChatHistory={setChatHistory}
                        />
                    </div>
                )
            ))}
        </div>
    );
}

export default App;
